






































































































import Vue from "vue";
import { VueEditor } from "vue2-editor";
import HelpStoreModule from "@/store/modules/help";
import { createNamespacedHelpers } from "vuex";
import { tags } from "./features";

const { mapGetters: helpGetters, mapActions: helpActions } =
  createNamespacedHelpers("_HELP_CREATE");

export default Vue.extend<any, any, any, any>({
  name: "NewThread",
  components: { VueEditor },
  data: () => ({
    tags,
    tab: "article",
    tabs: ["article", "video"],
    isLoading: false,
    thread: {
      title: "",
      content: "",
      tags: [],
    },
    video: {
      title: "",
      content: "",
      tags: [],
      video: undefined as any,
    },
  }),
  computed: {
    ...helpGetters(["tagPage"]),
  },
  methods: {
    ...helpActions(["createArticle", "createVideo"]),
    selectVideo(file: File) {
      this.video.video = file;
    },
    resetForm() {
      this.thread = {
        title: "",
        content: "",
        tags: [],
      };
    },
    saveArticle() {
      this.isLoading = true;
      this.createArticle({ ...this.thread })
        .then((result) => {
          console.log(result.data);
          this.resetForm();
          this.$emit("cancel");
        })
        .finally(() => (this.isLoading = false));
    },
    saveVideo() {
      this.isLoading = true;
      const { title, tags, video, content } = this.video;
      const data = new FormData();
      data.append("title", title);
      data.append("content", content);
      for (var i = 0; i < tags.length; i++) {
        data.append("tags", tags[i]);
      }
      data.append("video", video);
      this.createVideo(data)
        .then((result) => {
          console.log(result);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("_HELP_CREATE")) {
      this.$store.registerModule("_HELP_CREATE", HelpStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("_HELP_CREATE");
  },
});
