






























































































import Vue from "vue";

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

const comments = [
  {
    author: "JD",
    comment: "Lorem ipsum, dolor sit amet consectetur adipisicing elit",
  },
];
export default Vue.extend<any, any, any, any>({
  name: "ForumArticle",
  props: {
    single: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    comments,
    apiUrl: VUE_APP_API_URL,
  }),
  methods: {
    selectArticle(comment = false) {
      this.$emit("view-article", { comment: true });
    },
  },
});
