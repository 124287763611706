











































import Vue from "vue";
import ForumArticle from "@/components/help/article.vue";
import HelpStoreModule from "@/store/modules/help";
import { createNamespacedHelpers } from "vuex";
import NewThread from "@/components/help/newThread.vue";

const { mapGetters: helpGetters, mapActions: helpActions } =
  createNamespacedHelpers("_HELP");

export default Vue.extend<any, any, any, any>({
  name: "Forum",
  components: { ForumArticle, NewThread },
  data: () => ({
    single: false,
    newThreadDialog: false,
  }),
  computed: {
    ...helpGetters(["articlePage"]),
    articles(): any[] {
      return this.articlePage?.docs;
    },
  },
  created() {
    this.fetchHelpArticles();
  },
  methods: {
    ...helpActions(["fetchHelpArticles"]),
    listen(data: any) {
      this.single = true;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("_HELP")) {
      this.$store.registerModule("_HELP", HelpStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("_HELP");
  },
});
