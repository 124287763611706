import { HelpArticle, HelpTag, Page } from "@/types";
import { api } from "@/util/axios";
import { Module } from "vuex";

type helpState = {
  tagPage: Page<HelpTag>;
  articlePage: Page<HelpArticle>;
};

const help: Module<helpState, unknown> = {
  namespaced: true,
  state: () => ({
    tagPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    articlePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    tagPage: (state) => state.tagPage,
    articlePage: (state) => state.articlePage,
  },
  mutations: {
    SET_TAGS: (state, data) => {
      state.tagPage = data;
    },
    SET_ARTICLES: (state, data) => {
      state.articlePage = data;
    },
  },
  actions: {
    async fetchTags(context, params = "") {
      return await api
        .get(`/v1/help-tag${params}`)
        .then((response) => {
          console.log(response.data);
          context.commit("SET_TAGS", response.data.tagPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchHelpArticles(context, params = "") {
      return await api
        .get(`/v1/help${params}`)
        .then((response) => {
          context.commit("SET_ARTICLES", response.data.articlePage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createVideo(context, payload) {
      return await api
        .post("/v1/help/video", payload)
        .then((response) => {
          context.dispatch("fetchHelpArticles");
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createArticle(context, payload) {
      return await api
        .post("/v1/help", { ...payload })
        .then((response) => {
          context.dispatch("fetchHelpArticles");
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default help;
