










import Vue from "vue";
import Forum from "@/components/help/forum.vue";

export default Vue.extend<any, any, any, any>({
  name: "GettingStarted",
  components: { Forum },
});
