export const tags: any[] = [
  {
    title: "Analytics",
    description: "",
    url: "modules/analytics",
  },
  {
    title: "Clients",
    description: "",
    url: "modules/clients",
  },
  {
    title: "Calendar",
    description: "",
    url: "modules/calendar",
  },
  {
    title: "Employees",
    description: "",
    url: "modules/staffs",
  },
  {
    title: "Services",
    description: "",
    url: "modules/services",
  },
  {
    title: "Inventory",
    description: "",
    url: "modules/inventory",
  },
  {
    title: "Ahidi Shop",
    description: "",
    url: "modules/shop",
  },
  {
    title: "Memberships",
    description:
      "Having a Membership basically means belonging to a certain group or organization or being part of; being a member of an organization.",
    url: "modules/memberships",
  },
  {
    title: "Payments",
    description: "",
    url: "",
  },
  {
    title: "Website Builder",
    description: "",
    url: "modules/web-builder",
  },
  {
    title: "Marketing",
    description: "",
    url: "",
  },
  {
    title: "Messaging",
    description: "",
    url: "",
  },
  {
    title: "Settings",
    description: "",
    url: "",
  },
];
